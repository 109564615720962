import React from 'react'
import 'styles/main.scss'
import 'bootstrap/dist/css/bootstrap.min.css';
import Helmet from 'react-helmet';

export default ({ children }) => {
    return(
        <React.Fragment>
            <Helmet>
            <meta name="apple-mobile-web-app-title" content="Trinity Web" />
            <meta name="apple-mobile-web-app-capable" content="yes" />
            <meta name="apple-mobile-web-app-status-bar-style" content="white" />
            <title>Trinity | Resume</title>
            </Helmet>
            <div>
                {children}
            </div>
        </React.Fragment>
    )
}