import React from "react";
import ResumeLayout from "src/layouts/Resume";
import {
  GoDeviceMobile,
  GoMail,
  GoLocation,
  GoMarkGithub,
} from "react-icons/go";
import { AiOutlineLinkedin } from "react-icons/ai";
import { TiVideo, TiWorld } from "react-icons/ti";
import { MdExitToApp, MdComputer, MdSchool } from "react-icons/md";
import { FaInfoCircle, FaAward } from "react-icons/fa";
import { graphql } from "gatsby";

export default ({ data }) => {
  const __ = data.allResumeJson.edges[0].node;
  const __rc = data.allResumeContentJson.edges[0].node;

  const section_items = (section_data, section_name, extra) => {
    // SUMMARY
    if (section_name === "summary-highlights") {
      return section_data.summary.highlights.map((item, i) => {
        return (
          <li key={i} className="small-li">
            {item}
          </li>
        );
      });
    }

    if (section_name === "summary-desc") {
      return (
        <div>
          <p>{section_data.summary.desc}</p>
        </div>
      );
    }

    // WORK
    if (section_name === "work") {
      return section_data.work.map((item, i) => {
        return (
          <div className="list_block_flexed">
            <div key={i} className="list_block">
              <div className="list_header_flexed">
                <div>
                  <h2 className="item-titles">
                    {item.company} - {item.location}
                  </h2>
                  <p>
                    <em>{item.position}</em>
                  </p>
                </div>
                <div className="item-titles date-block">
                  {item.startDate} {item.endDate ? ` - ${item.endDate}` : null}
                </div>
              </div>

              <p>{item.description}</p>

              <ul>
                {item.highlights.map((highlight) => {
                  return <li className="small-li">{highlight}</li>;
                })}
              </ul>

              {item.projects !== null && <h2 className="item-titles">Projects</h2>}
              <ul>
                {item.projects !== null &&
                  item.projects.map((item, i) => (
                    <div key={i} className="list_block projects">
                      <div className="list_project_header_flexed">
                        <h2 className="item-titles project-titles">
                          <MdComputer /> {item.name}
                        </h2>
                        <div>
                          {item.githuburl ? (
                            <a
                              href={item.githuburl}
                              target="_blank"
                              rel="noopener noreferrer"
                            >
                              <GoMarkGithub className="hide-on-print" /> GitHub
                            </a>
                          ) : null}
                          {item.githuburl && item.videourl ? (
                            <span className="hide-on-print"> | </span>
                          ) : null}
                          {item.videourl ? (
                            <a
                              className="hide-on-print"
                              href={item.videourl}
                              target="_blank"
                              rel="noopener noreferrer"
                            >
                              <TiVideo /> Video
                            </a>
                          ) : null}
                          {item.liveurl && item.videourl ? (
                            <span className="hide-on-print"> | </span>
                          ) : null}
                          {item.liveurl ? (
                            <a
                              href={item.liveurl}
                              target="_blank"
                              rel="noopener noreferrer"
                            >
                              <MdExitToApp className="hide-on-print" /> Live
                            </a>
                          ) : null}
                          {item.more_info ? (
                            <span className="hide-on-print"> | </span>
                          ) : null}
                          {item.more_info ? (
                            <a
                              className="hide-on-print"
                              href={item.more_info}
                              target="_blank"
                              rel="noopener noreferrer"
                            >
                              <FaInfoCircle /> More Info
                            </a>
                          ) : null}
                        </div>
                      </div>
                      <p className="project-description">{item.description}</p>
                      <p>
                        <b>Tech Used</b>
                        <br />
                        <ul>
                          {item.tech.map((t) => {
                            return <li>{t}</li>;
                          })}
                        </ul>
                      </p>
                    </div>
                  ))}
              </ul>
            </div>
          </div>
        );
      });
    }

    // SKILLS
    if (section_name === "skills") {
      return section_data.skills.map((item, i) => {
        if (extra === item.type) {
          return (
            <span key={i} className="skill-item">
              {item.name}
            </span>
          );
        }
        return "";
      });
    }

    // EDUCATION
    if (section_name === "education") {
      return section_data.education.map((item, i) => {
        return (
          <div key={i} className="list_block ed_item">
            <h2 className="item-titles">
              <MdSchool /> {item.institution} - {item.location}
            </h2>
            <p>
              {item.studyType}
              {item.emphasis && `, ${item.emphasis}`}
            </p>
          </div>
        );
      });
    }

    // AWARDS
    // if(section_name === "awards") {
    //   return section_data.awards.map((item, i)=> {
    //     return (
    //       <div key={i} className="list_block">
    //         <h2 className="item-titles"><FaAward /> {item.date} - {item.title}
    //         </h2>
    //           <a href={item.website} target="_blank" rel="noopener noreferrer">{item.awarder}</a>
    //       </div>
    //     )
    //   })
    // }

    // PROJECTS
    if (section_name === "projects") {
      return section_data.projects.map((item, i) => {
        return (
          <div key={i} className="list_block projects">
            <h2 className="item-titles project-titles">
              <MdComputer /> {item.name}
            </h2>
            {item.githuburl ? (
              <a
                href={item.githuburl}
                target="_blank"
                rel="noopener noreferrer"
              >
                <GoMarkGithub className="hide-on-print" /> GitHub
              </a>
            ) : null}
            {item.githuburl && item.videourl ? (
              <span className="hide-on-print"> | </span>
            ) : null}
            {item.videourl ? (
              <a
                className="hide-on-print"
                href={item.videourl}
                target="_blank"
                rel="noopener noreferrer"
              >
                <TiVideo /> Video
              </a>
            ) : null}
            {item.liveurl && item.videourl ? (
              <span className="hide-on-print"> | </span>
            ) : null}
            {item.liveurl ? (
              <a href={item.liveurl} target="_blank" rel="noopener noreferrer">
                <MdExitToApp className="hide-on-print" /> Live
              </a>
            ) : null}
            {item.more_info ? <span className="hide-on-print"> | </span> : null}
            {item.more_info ? (
              <a
                className="hide-on-print"
                href={item.more_info}
                target="_blank"
                rel="noopener noreferrer"
              >
                <FaInfoCircle /> More Info
              </a>
            ) : null}
            <p className="project-description">{item.description}</p>
            <p>
              <b>Tech Used</b>
              <br />
              <ul>
                {item.tech.map((t) => {
                  return <li className="tech-used">{t}</li>;
                })}
              </ul>
            </p>
          </div>
        );
      });
    }

    // VOLUNTEER
    if (section_name === "volunteer") {
      return section_data.volunteer.map((item, i) => {
        return (
          <div className="list_block_flexed">
            <div className="item-titles date-block">
              {item.startDate} {item.endDate ? ` - ${item.endDate}` : null}
            </div>
            <div key={i} className="list_block">
              <h2 className="item-titles">
                {item.company} - {item.location}
              </h2>
              <p>{item.position}</p>
            </div>
          </div>
        );
      });
    }
  };
  return (
    <ResumeLayout>
      <div>
        <div className="resume_heading">
          <div>
            <div className="name">{__rc.basics.name}</div>
            <div className="header_subheading">{__.postionAppliedFor}</div>
          </div>
          <div className="personal_info">
            <div className="additional_info">
              <div>
                <GoDeviceMobile /> {__rc.basics.phone}
              </div>
              <div>
                <GoMail />{" "}
                <a href={`mailto:${__rc.basics.email}`}>{__rc.basics.email}</a>
              </div>
              <div>
                <TiWorld />{" "}
                <a href={__rc.basics.website.url}>{__rc.basics.website.text}</a>
              </div>
              <div>
                <GoMarkGithub />{" "}
                <a href={__rc.basics.github.url}>{__rc.basics.github.text}</a>
              </div>
              <div>
                <AiOutlineLinkedin />{" "}
                <a href={__rc.basics.linkedin.url}>
                  {__rc.basics.linkedin.text}
                </a>
              </div>
            </div>
          </div>
        </div>

        <div className="content-parent">
          {/* COLUMN ONE */}
          <div className="col-one">
            {/* SUMMARY */}
            <div className="resume_section">
              <div className="section_heading">{__.headings.summary}</div>
              {section_items(__, "summary-desc")}
              <ul  className="list_block">
                    {section_items(__, "summary-highlights")}
                  </ul>
            </div>

            {/* RELEVANT EXPERIENCE */}
            <div className="resume_section">
              <div className="section_heading">{__.headings.work}</div>
              {section_items(__, "work")}
            </div>

            {/* EDUCATION */}
            <div className="resume_section">
              <div className="section_heading">{__.headings.education}</div>
              <div className="education">
                {section_items(__rc, "education")}
              </div>
            </div>

            {/* VOLUNTEER
            <div className="resume_section">
              <div className="section_heading">{__.headings.volunteer}</div>
                {section_items(__rc, "volunteer")}
            </div> */}
          </div>
          {/* COLUMN ONE */}

          {/* COLUMN TWO */}
          <div className="col-two">
            {/* SKILLS */}
            <div className="resume_section">
              <div className="section_heading">{__.headings.skills}</div>
              <div className="skills">
                <div className="list_block skills-group">
                  <span className="skill-titles">Frameworks: </span>
                  {section_items(__rc, "skills", "framework")}
                </div>

                <div className="list_block skills-group">
                  <span className="skill-titles">Platforms: </span>
                  {section_items(__rc, "skills", "platform")}
                </div>
                <div className="list_block skills-group">
                  <span className="skill-titles">Software Tools: </span>
                  {section_items(__rc, "skills", "tool")}
                </div>
                <div className="list_block skills-group">
                  <span className="skill-titles">Deployment Tools: </span>
                  {section_items(__rc, "skills", "deployment")}
                </div>

                <div className="list_block skills-group">
                  <span className="skill-titles">Languages: </span>
                  {section_items(__rc, "skills", "language")}
                </div>

                <div className="list_block skills-group">
                  <span className="skill-titles">Libraries: </span>
                  {section_items(__rc, "skills", "library")}
                </div>

                {/* <div className="list_block skills-group">
                    <span className="skill-titles">PM Tools: </span>
                    {section_items(__rc, "skills", "pm-tool")}
                  </div>

                  <div className="list_block skills-group">
                    <span className="skill-titles">Design Tools: </span>
                    {section_items(__rc, "skills", "design-tool")}
                  </div> */}
              </div>
            </div>

            {/* AWARDS */}
            {/* <div className="section_heading">{__.headings.awards}</div>
                {section_items(__rc, "awards")} */}

            {/* PROJECTS */}
            <div className="section_heading">{__.headings.projects}</div>
            {section_items(__rc, "projects")}
          </div>
          {/* COLUMN TWO */}
        </div>
      </div>
    </ResumeLayout>
  );
};

export const query = graphql`
  query($slug: String!) {
    allResumeContentJson(
      filter: { templateKey: { eq: "dev-resume-constants" } }
    ) {
      edges {
        node {
          basics {
            name
            email
            phone
            location {
              address
            }
            website {
              text
              url
            }
            github {
              text
              url
            }
            linkedin {
              text
              url
            }
          }
          education {
            institution
            location
            studyType
            emphasis
          }
          skills {
            name
            level
            type
          }
          projects {
            name
            description
            githuburl
            liveurl
            tech
          }
          awards {
            title
            date
            awarder
            summary
            website
          }
        }
      }
    }

    allResumeJson(filter: { fields: { slug: { eq: $slug } } }) {
      edges {
        node {
          postionAppliedFor
          headings {
            summary
            work
            education
            skills
            projects
            awards
          }
          summary {
            desc
            highlights
          }
          work {
            company
            location
            position
            website
            startDate
            endDate
            highlights
            description
            projects {
              name
              description
              githuburl
              liveurl
              tech
            }
          }
        }
      }
    }
  }
`;
